import axios from 'axios';
import { defineComponent } from 'vue';
import Loader from '@/core/components/ui/Loader.vue';
import StringHelper from '@/core/helpers/string.helper';
import { 
// VUE_APP_EPC_API,
VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL, VUE_APP_PROPERTY_DATA_API, VUE_APP_PROPERTY_DATA_URL, } from '../../../config';
const DISPLAY = 'display';
const DOMESTIC = 'domestic';
const NON_DOMESTIC = 'non-domestic';
export default defineComponent({
    components: {
        Loader
    },
    data() {
        return {
            DISPLAY,
            DOMESTIC,
            NON_DOMESTIC,
            searchKey: '',
            addresses: [],
            selectedKey: null,
            findingAddress: false,
            epcFilter: DOMESTIC,
            epcOptions: [{
                    value: DISPLAY,
                    label: StringHelper.capitalizeFirstLetter(DISPLAY)
                },
                {
                    value: NON_DOMESTIC,
                    label: StringHelper.capitalizeFirstLetter(NON_DOMESTIC)
                },
                {
                    value: DOMESTIC,
                    label: StringHelper.capitalizeFirstLetter(DOMESTIC)
                }],
            epcUrls: {
                display: 'https://epc.opendatacommunities.org/api/v1/display',
                domestic: 'https://epc.opendatacommunities.org/api/v1/domestic',
                'non-domestic': 'https://epc.opendatacommunities.org/api/v1/non-domestic'
            },
            uprn: null,
            uprnTitleData: null,
            // uprnData: null as any,
            loadingUprnDataResult: false,
            loadingUprnTitleResult: false,
            loadingTitleUseClassResult: false,
            loadingTitleResult: false,
            // hasNoUprnData: false as boolean,
            hasNoUprnTitleData: false,
            titleData: [],
            titleUseClassData: [],
            titleUseClass: false,
            title: false,
        };
    },
    methods: {
        onChangeFilter() {
            this.onSearchAddress();
        },
        async onSearchAddress() {
            this.addresses = [];
            if (this.searchKey.length > 3) {
                this.findingAddress = true;
                // reset
                this.uprnTitleData = null;
                // this.uprnData = null;
                this.titleUseClassData = [];
                this.titleData = [];
                this.hasNoUprnTitleData = false;
                // this.hasNoUprnData = false;
                this.titleUseClass = false;
                this.title = false;
                this.selectedKey = null;
                // const url = `${this.epcUrls[this.epcFilter]}/search`;
                const postcode = this.searchKey;
                await axios(
                // `${url}?postcode=${postcode}&size=500`,
                `${VUE_APP_OS_POSTCODE_URL}?postcode=${postcode}&output_srs=EPSG:4326&key=${VUE_APP_OS_API}`, {
                    headers: {
                        Accept: 'application/json',
                    }
                }).then((response) => {
                    this.addresses = response.data.results || [];
                    console.log(response.data.results, 'response');
                    this.findingAddress = false;
                    console.log(this.addresses, 'address');
                }).catch(() => {
                    this.$notify.error({
                        title: 'Error in finding address',
                        message: 'Please enter a valid postcode'
                    });
                });
            }
        },
        async onSelectAddress(DPA) {
            this.loadingUprnTitleResult = true;
            this.titleUseClass = false;
            this.title = false;
            this.titleUseClassData = [];
            this.titleData = [];
            // if current uprn !== selected address reset the data to null
            const { UPRN } = DPA;
            this.selectedKey = UPRN;
            if (UPRN) {
                this.uprn = UPRN;
                this.getUprnTitleData(UPRN);
            }
            else {
                this.hasNoUprnTitleData = true;
                this.loadingUprnTitleResult = false;
            }
        },
        async getUprnTitleData(uprn) {
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/uprn-title?key=${VUE_APP_PROPERTY_DATA_API}&uprn=${uprn}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.uprnTitleData = response.data;
                this.hasNoUprnTitleData = false;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error in finding address',
                    message: 'Please enter a valid postcode'
                });
                this.hasNoUprnTitleData = true;
            })
                .finally(() => {
                this.loadingUprnTitleResult = false;
            });
        },
        async getAllTitleData() {
            this.loadingTitleResult = true;
            let titleNumber;
            const getTitleDataPromises = [];
            if (this.title && !this.titleData.length && this.uprnTitleData) {
                for (let i = 0; i < this.uprnTitleData.data.title_data.length; i++) {
                    titleNumber = this.uprnTitleData.data.title_data[i].title_number;
                    console.log(titleNumber, 'title number');
                    getTitleDataPromises.push(this.getTitleData(titleNumber));
                }
                await Promise.all(getTitleDataPromises)
                    .catch(() => {
                    this.$notify.error({
                        title: 'Error in getting title data',
                        message: 'Error occurred when trying to fetch title data. Please try again.'
                    });
                })
                    .finally(() => {
                    this.loadingTitleResult = false;
                });
            }
        },
        async getTitleData(titleNumber) {
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/title?key=${VUE_APP_PROPERTY_DATA_API}&title=${titleNumber}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.titleData.push(response.data);
            });
        },
        async getAllTitleUseClassData() {
            this.loadingTitleUseClassResult = true;
            let titleNumber;
            const getTitleUseClassDataPromises = [];
            if (this.titleUseClass && !this.titleUseClassData.length && this.uprnTitleData) {
                for (let i = 0; i < this.uprnTitleData.data.title_data.length; i++) {
                    titleNumber = this.uprnTitleData.data.title_data[i].title_number;
                    getTitleUseClassDataPromises.push(this.getTitleUseClassData(titleNumber));
                }
                await Promise.all(getTitleUseClassDataPromises)
                    .catch(() => {
                    this.$notify.error({
                        title: 'Error in getting title use class data',
                        message: 'Error occurred when trying to fetch title use class data. Please try again.'
                    });
                })
                    .finally(() => {
                    this.loadingTitleUseClassResult = false;
                });
            }
        },
        async getTitleUseClassData(titleNumber) {
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/title-use-class?key=${VUE_APP_PROPERTY_DATA_API}&title=${titleNumber}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.titleUseClassData.push(response.data);
            });
        }
    },
});
